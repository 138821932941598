import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useGetTags from '../../components/Hooks/data/getTags/getTags';
import NavFilter from '../../components/Filters/NavFilter/NewNavFilter';
import { StoreContext } from '../../stores';
import DateFilter from '../../components/Filters/DateFilter/NewDateFilter';
import SelectFilter from '../../components/Filters/SelectFilter/NewSelectFilter';
import DomainFilter from '../../components/Filters/DomainFilter/NewDomainFilter';
import TermsFilter from '../../components/Filters/TermsFilter/NewTermsFilter';
import RangeFilter from '../../components/Filters/RangeFilter/NewRangeFilter';
import Accordion from '../../components/Accordion/Accordion';
import { useTranslation } from 'react-i18next';
import TagsFilter from '../../components/Filters/TagsFilter/NewTagsFilter';
import DomainsStore from './newDomains.store';
import { keywordtypes, engines } from '../../Utils/rawData/rawData';
import TagListMultiTerms from '../../components/Tag/TagListMultiTerms/TagListMultiTerms';
import GroupActions from '../../components/GroupActions/GroupActions';
import MagicTable from '../../components/MagicTable/MagicTable';
import CheckboxCell from '../../components/MagicTable/CheckboxCell/CheckboxCell';
import LocalizationFlagArrayCell from '../../components/MagicTable/LocalizationFlagCell/LocalizationFlagArrayCell';
import DomainsVisibilityCell from '../../components/Domains/DomainsVisibilityCell/DomainsVisibilityCell';
import DomainsRealPositonAvgCell from '../../components/Domains/DomainsRealPositonAvgCell/DomainsRealPositonAvgCell';
import DealerTagCell from '../../components/MagicTable/DealerTagCell/NewDealerTagCell';
import DomainsPortalCell from '../../components/Domains/DomainsPortalCell/NewDomainsPortalCell';
import BrandsFilter from '../../components/Filters/BrandsFilter/NewBrandsFilter';
import LocalizationFilter from '../../components/Filters/LocalizationFilter/NewLocalizationFilter';
import NameWithLinkAndSearchCell from '../../components/MagicTable/NameWithLinkAndSearchCell/NameWithLinkAndSearchCell';
import InputFilter from '../../components/Filters/InputFilter/InputFilter';
import RegionFilter from '../../components/Filters/LocalizationFilter/RegionFilter';
//import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/NewCompanyAccountsFilter';
import CursorPagination from '../../components/Pagination/CursorPagination';
import { BrandProvider } from '../../components/brandContext';

import classes from './Domains.module.css';

function Domains() {
  const { authStore, uiStore } = useContext(StoreContext),
    { t } = useTranslation(),
    { currentUser, isAdmin, token } = authStore,
    { tags, tagLoading, tagFetch } = useGetTags('tag', currentUser, isAdmin);

  const location = useLocation();
  const [domainsStore] = useState(
    () => new DomainsStore(currentUser.id, token, isAdmin)
  );

  useEffect(() => {
    uiStore.setTitle(t('Domains'));
    if (location.state?.filters && location.state.filters !== 'undefined') {
      domainsStore.searchToFilters(location.state.filters);
      domainsStore.handleFetch();
    } else {
      domainsStore.firstFetch();
    }

    return () => {
      domainsStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    domainsStore.handleNext();
  };
  const handlePrevious = () => {
    domainsStore.handlePrevious();
  };

  const handlePerPage = (perPage) => {
    domainsStore.setPage({ type: 'perPage', value: perPage });
  };

  const filterList = {
    date: [
      <DateFilter
        key='date'
        name='date'
        label='date'
        dateMin={domainsStore.dateFilterMin}
      />,
    ],
    generic: [
      //<CompanyAccountsFilter
      //  key='companyID'
      //  label='company account'
      //  name='companyID'
      //  titleMode
      ///>,
      <BrandsFilter titleMode key='brand' label='brand' name='brandID' />,
      <LocalizationFilter
        titleMode
        key='localization'
        label='country'
        name='country'
      />,
      <RegionFilter
        key='region'
        titleMode
        basedOn='country'
        label='Targeted location'
        name='region'
      />,
      <SelectFilter
        key='engine'
        label='engine'
        name='engine'
        options={engines}
      />,
      <SelectFilter
        key='device'
        titleMode
        label='device'
        name='device'
        options={[
          { value: 'tablet', label: t('Tablet') },
          { value: 'mobile', label: t('Mobile') },
          { value: 'desktop', label: t('Desktop') },
        ]}
      />,
      <SelectFilter
        key='type'
        titleMode
        name='type'
        label='type of campaign'
        placeholder={t('Type of campaign')}
        options={[
          { label: t('Ads'), value: 'ads' },
          { label: 'Shopping', value: 'shopping' },
        ]}
      />,
    ],
    domainAndTerms: [
      <DomainFilter key='domain' titleMode label='domain' name='domain' />,
      <TagsFilter
        key='advertiserTag'
        titleMode
        label='tags (dealer)'
        name='advertiserTag'
        scope='tag'
        menuPlacement='top'
      />,
      <TermsFilter
        titleMode
        key='keywordContent'
        label='keywords'
        name='keywordContent'
        filterKey='term'
        options={{ menuPlacement: 'top' }}
        nameForSearch='term'
      />,
      <TagsFilter
        key='keywordTag'
        titleMode
        label='tags (keywords)'
        name='keywordTag'
        scope='term'
        menuPlacement='top'
      />,
      <SelectFilter
        key='keywordType'
        titleMode
        label='keyword type'
        name='keywordType'
        options={keywordtypes.map((o) => {
          return {
            value: o.value,
            label: t(o.label),
          };
        })}
        menuPlacement='top'
      />,
      <InputFilter
        placeholder='Search...'
        key='title'
        label='title'
        name='adTitle'
      />,
      <InputFilter
        placeholder='Search...'
        key='description'
        label='description'
        name='adDescription'
      />,
      <RangeFilter
        key='position'
        label='position of ads'
        name='position'
        marks={{
          1: { label: '1' },
          15: { label: '15' },
        }}
        step={1}
        min={1}
        max={15}
        defaultValue={[0, 15]}
      />,
    ],
  };

  const standaloneFilters = [];
  Object.values(filterList).forEach((filters) => {
    standaloneFilters.push(...filters);
  });

  const [brandID, setBrandID] = useState(undefined);

  useEffect(() => {
    if (!domainsStore.filters) {
      setBrandID();
      return;
    }
    setBrandID(domainsStore.filters['brandID']?.valueNew);
  }, [domainsStore.filters]);

  return (
    <BrandProvider brandID={brandID}>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
          {currentUser && (
            <NavFilter
              isLoading={domainsStore.isLoadingCount}
              pageStore={domainsStore}
              counters={domainsStore.counters}
              filters={domainsStore.filters}
              standaloneFilters={standaloneFilters}
            >
              <Accordion title={'Last detection date'} unmountOnExit={false}>
                <div className={classes.filterContainer}>
                  {filterList['date']}
                </div>
              </Accordion>
              <Accordion title={'Generic'} unmountOnExit={false}>
                <div className={classes.filterContainer}>
                  {filterList['generic']}
                </div>
              </Accordion>
              <Accordion title={'Domain & terms'} unmountOnExit={false}>
                <div className={classes.filterContainer}>
                  {filterList['domainAndTerms']}
                </div>
              </Accordion>
            </NavFilter>
          )}
          <GroupActions
            selected={domainsStore.selectedItems}
            actions={[]}
            isLoading={domainsStore.isLoadingAction}
          >
            <TagListMultiTerms
              selectedItems={domainsStore.selectedItems}
              scope='tag'
              tags={tags}
              refetchTag={() => tagFetch()}
              refetch={() => domainsStore.handleFetch()}
              commonTags={domainsStore.commonTagsForSelectItems}
            />
          </GroupActions>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={domainsStore}
              exportLink={domainsStore.exportLink}
              exportName={'domains'}
              checkedField='id'
              disableFirstFetch
            >
              <CheckboxCell field='selectAll' checkedField='id' />
              <LocalizationFlagArrayCell field='countries' />
              <DomainsVisibilityCell field='nbViews' />
              <NameWithLinkAndSearchCell field='domain' />
              <DomainsPortalCell field='portal' />
              <DomainsRealPositonAvgCell field='averagePosition' />
              <DealerTagCell
                field='categories'
                tags={tags}
                isLoading={tagLoading}
                tagFetch={tagFetch}
              />
            </MagicTable>
          </div>
        </div>
        <CursorPagination
          total={domainsStore.count}
          perPage={domainsStore.perPage}
          currentPage={domainsStore.page}
          next={domainsStore.hasNextPage ? handleNext : undefined}
          previous={domainsStore.hasPreviousPage ? handlePrevious : undefined}
          onChangePerPage={handlePerPage}
        />
      </div>
    </BrandProvider>
  );
}

export default observer(Domains);
