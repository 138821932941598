import { observer } from 'mobx-react';
import useTagsFilter from './useTagsFilter';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/AsyncCreateFilter';
import { useBrandContext } from '../../../components/brandContext';

interface Props extends FilterChild {
  readonly scope: string
}

function TagsFilter(props: Props) {
  const { brandID } = useBrandContext();
  const useProps = useTagsFilter(props.scope, brandID),
    passProps = { ...props, ...useProps };

  return <AsyncCreateFilter {...passProps} />;
}

export default observer(TagsFilter);
