import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CursorPagination from '../../components/Pagination/CursorPagination';
import AdvertiserGroupAction from '../../components/Advertiser/AdvertiserGroupAction/AdvertiserGroupAction';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { useTranslation } from 'react-i18next';
import useGetTags from '../../components/Hooks/data/getTags/getTags';
import { StoreContext } from '../../stores';
import SelectFilter from '../../components/Filters/SelectFilter/SelectFilter';
import DomainFilter from '../../components/Filters/DomainFilter/DomainFilter';
import { statusOptions } from '../../Utils/filter/status.data';
import AdvertisersStore from './advertisers.store';
import TagsFilter from '../../components/Filters/TagsFilter/TagsFilter';
import DateFilter from '../../components/Filters/DateFilter/DateFilter';
import CreateSelectFilter from '../../components/Filters/CreateSelectFilter/CreateSelectFilter';
import MagicTable from '../../components/MagicTable/MagicTable';
import CheckboxCell from '../../components/MagicTable/CheckboxCell/CheckboxCell';
import EngineCell from '../../components/MagicTable/EngineCell/EngineCell';
import AdvertiserPortalCell from '../../components/Advertiser/AdvertiserPortalCell/AdvertiserPortalCell';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StatusCell from '../../components/MagicTable/StatusCell/StatusCell';
import DealerTagCell from '../../components/MagicTable/DealerTagCell/DealerTagCell';
import AdvertiserActionsCell from '../../components/Advertiser/AdvertiserActionsCell/AdvertiserActionsCell';
import BrandsFilter from '../../components/Filters/BrandsFilter/BrandsFilter';
import AdvertiserUpdateDateCell from '../../components/Advertiser/AdvertiserUpdateDateCell/AdvertiserUpdateDateCell';
import LocalizationFlagArrayCell from '../../components/MagicTable/LocalizationFlagCell/LocalizationFlagArrayCell';
import LocalizationFilter from '../../components/Filters/LocalizationFilter/LocalizationFilter';
import NameWithLinkAndSearchCell from '../../components/MagicTable/NameWithLinkAndSearchCell/NameWithLinkAndSearchCell';
import containsValueMapper from '../../Utils/filter/containsValueMapper';
import { BrandProvider } from '../../components/brandContext';

function Advertisers() {
  const { authStore, uiStore } = useContext(StoreContext),
    { t } = useTranslation(),
    { currentUser, isAdmin } = authStore,
    { tags, tagLoading, tagFetch } = useGetTags('tag', currentUser, isAdmin),
    [advertisersStore] = useState(
      () =>
        new AdvertisersStore(currentUser?.id, isAdmin, tags, authStore.token)
    );

  const handleNext = () => {
    if (advertisersStore.next) {
      advertisersStore.changePage('next');
    }
  };

  const handlePrevious = () => {
    if (advertisersStore.previous) {
      advertisersStore.changePage('previous');
    }
  };

  const handleChangePerPage = (perPage: number) => {
    advertisersStore.handleChangePerPage(perPage);
  };

  useEffect(() => {
    uiStore.setTitle(t('Advertisers'));

    return () => {
      advertisersStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAdmin !== advertisersStore.isAdmin) {
      advertisersStore.setIsAdmin(isAdmin);
    }
    // eslint-disable-next-line
  }, [isAdmin]);

  useEffect(() => {
    if (tags !== advertisersStore.tags && tagLoading === false) {
      advertisersStore.setTags(tags);
    }
    // eslint-disable-next-line
  }, [tags, tagLoading]);


  const [brandID, setBrandID] = useState<string[] | undefined>(undefined);
  useEffect(() => {
    if (!advertisersStore.filters) {
      setBrandID(undefined);
      return;
    }

    setBrandID(advertisersStore.filters['brandId']?.valueNew);
  }, [advertisersStore.filters]);

  return (
    <BrandProvider brandID={brandID}>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
          {currentUser && (
            <NavFilter
              isLoading={advertisersStore.isLoadingCount}
              pageStore={advertisersStore}
              counters={advertisersStore.counters}
            >
              {/* @ts-expect-error */}
              <DateFilter name='lastView' label='lastView' />
              <BrandsFilter label='brand' name='brandId' />
              {/* @ts-expect-error */}
              <SelectFilter
                label='provider'
                name='engine'
                options={[
                  { label: 'Google', value: 'google' },
                  { label: 'Bing', value: 'bing' },
                  { label: 'Yahoo', value: 'yahoo' },
                ]}
              />
              {/* @ts-expect-error */}
              <LocalizationFilter
                label='countries'
                type='country'
                name='countries'
              />
              {/* @ts-expect-error */}
              <LocalizationFilter label='languages' name='languages' />
              <DomainFilter label='master' name='master' />
              {/* @ts-expect-error */}
              <SelectFilter
                label='type of campaign'
                name='presence'
                placeholder={t('Type of campaign')}
                options={[
                  { label: t('Ads'), value: 'ads' },
                  { label: 'Shopping', value: 'shopping' },
                ]}
              />
              {/* @ts-expect-error */}
              <SelectFilter
                label='status'
                name='status'
                options={statusOptions.map((o) => ({
                  label: t(o.label),
                  value: o.value,
                }))}
              />
              {/* @ts-expect-error */}
              <SelectFilter
                label='Reappearance'
                name='metas.originalStatus'
                reactSelectOptions={{ isMulti: false }}
                options={[
                  {
                    label: t('Only "reappearance"'),
                    value: 'reappearance',
                  },
                  {
                    label: t('Exclude "reappearance"'),
                    value: undefined,
                  },
                ]}
              />
              <TagsFilter
                label='tags (dealer)'
                name='dealer.categories.id'
                scope='tag'
              />
              <TagsFilter
                label='tags (keywords)'
                name='termRel.categories.id'
                scope='term'
              />
              {/* @ts-expect-error */}
              <DateFilter name='createdDate' label='createdDate' />
              {/* @ts-expect-error */}
              <DateFilter name='requestedDate' label='requestedDate' />
              {/* @ts-expect-error */}
              <DateFilter name='inprogressDate' label='inprogressDate' />
              {/* @ts-expect-error */}
              <DateFilter name='updatedDate' label='updatedDate' />
              {/* @ts-expect-error */}
              <DateFilter name='acceptedDate' label='acceptedDate' />
              {/* @ts-expect-error */}
              <DateFilter name='refusedDate' label='refusedDate' />
              {/* @ts-expect-error */}
              <CreateSelectFilter
                label='title'
                name='advertisementTitle'
                options={[]}
                reactSelectOptions={{
                  menuPlacement: 'top',
                  noOptionsMessage: () => '',
                  formatCreateLabel: (inputValue: any) =>
                    `${t('contain')} "${inputValue}"`,
                }}
                valueMapper={containsValueMapper}
              />
              {/* @ts-expect-error */}
              <CreateSelectFilter
                label='description'
                name='advertisementDescription'
                options={[]}
                reactSelectOptions={{
                  menuPlacement: 'top',
                  noOptionsMessage: () => '',
                  formatCreateLabel: (inputValue: any) =>
                    `${t('contain')} "${inputValue}"`,
                }}
                valueMapper={containsValueMapper}
              />
            </NavFilter>
          )}
          <AdvertiserGroupAction
            selects={advertisersStore.selected}
            setAdvertiserGroup={(status) =>
              advertisersStore.setAdvertiserGroup(status)
            }
          />
        </div>

        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={advertisersStore}
              exportLink={advertisersStore.exportLink}
              exportName={'advertisers'}
              checkedField='ID'
            >
              {/* @ts-expect-error */}
              <CheckboxCell checkedField='ID' field='selectAll' />
              <EngineCell field='engine' />
              <NameWithLinkAndSearchCell field='domain' />
              {/* @ts-expect-error */}
              <AdvertiserPortalCell field='portal' />
              <DateCell field='createdDate' />
              <LocalizationFlagArrayCell field='countries' />
              {/* @ts-expect-error */}
              <AdvertiserUpdateDateCell field='updatedDate' />
              <DateCell field='lastView' />
              <StatusCell field='status' />
              <DateCell field='lastStatusDate' />
              <DealerTagCell
                field='categories'
                tags={tags}
                isLoading={tagLoading}
                tagFetch={tagFetch}
              />
              {/* @ts-expect-error */}
              <AdvertiserActionsCell field='actions' />
            </MagicTable>
          </div>
        </div>
        <CursorPagination
          total={advertisersStore.count}
          perPage={advertisersStore.currentPerPage}
          currentPage={advertisersStore.currentPage}
          next={advertisersStore.next ? handleNext : undefined}
          previous={advertisersStore.previous ? handlePrevious : undefined}
          onChangePerPage={handleChangePerPage}
        />
      </div>
    </BrandProvider>
  );
}

export default observer(Advertisers);
