import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CSSTransition } from 'react-transition-group';
import ShortNavFilter from '../../components/Filters/NavFilter/ShortNavFilter';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../stores';
import SelectFilter from '../../components/Filters/SelectFilter/SelectFilter';
import DomainFilter from '../../components/Filters/DomainFilter/DomainFilter';
import BrandsFilter from '../../components/Filters/BrandsFilter/BrandsFilter';
import LocalizationFilter from '../../components/Filters/LocalizationFilter/LocalizationFilter';
import TermsFilter from '../../components/Filters/TermsFilter/TermsFilter';
import {
  statusActionsOptions,
  statusActionsSortOptions,
} from '../../Utils/filter/status.data';
import { keywordtypes, engines } from '../../Utils/rawData/rawData';
import TagsFilter from '../../components/Filters/TagsFilter/TagsFilter';
import DateFilter from '../../components/Filters/DateFilterNew/DateFilter';
import ActionsStore from './actions.store';
import FiltersPanel from './FiltersPanel';
import ActionPanel from './ActionPanel';
import classes from './Actions.module.css';
import { PanelFilterContentBlock } from '../../components/Filters/PanelFilter/PanelFilterContentWithBlocks';
import FiltersToggleButton from './FiltersToggleButton';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import useGetActions from '../../components/Hooks/data/getActions';
import CursorPagination from '../../components/Pagination/CursorPagination';
import NoResult from '../../assets/img/actionNoResult.svg';
import ErrorResult from '../../assets/img/actionError.svg';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import { Action } from '../../types/action.newBackend';
import { BrandProvider } from '../../components/brandContext';

function buildCriteria(filters: any) {
  return Object.keys(filters)
    .map((key) => ({ key, value: filters[key]?.valueNew }))
    .reduce(
      (acc, val) => ({
        ...acc,
        [val.key]: val.value,
      }),
      {}
    );
}

const CACHE_KEY = 'actions';

const ActionsContainer = () => {
  const { cacheStore, authStore } = useContext(StoreContext);
  const { currentUser } = authStore;
  const [ready, setReady] = useState(false);
  const [actionsStore] = useState(() => new ActionsStore(currentUser?.id));

  useEffect(() => {
    const init = async () => {
      await actionsStore.initPersist();
      setReady(true);
    };

    init();
    return () => {
      actionsStore.stopStore();
    };
  }, [actionsStore]);

  const [cachedData, setCachedData] = useState(() => {
    return cacheStore.get(CACHE_KEY);
  });

  const handleClearCache = () => {
    setCachedData(() => {
      cacheStore.clear(CACHE_KEY);
      return undefined;
    });
  };

  if (!ready) {
    return null;
  }

  return (
    <ActionsObserver
      actionsStore={actionsStore}
      savedState={cachedData}
      saveState={(data: SavedState) => cacheStore.save(CACHE_KEY, data)}
      clearState={handleClearCache}
    />
  );
};

const ActionsObserver = observer(Actions);

interface SavedState {
  selectedItemID: string;
  actionsData: any;
}

function Actions({
  actionsStore,
  saveState,
  savedState,
  clearState,
}: {
  actionsStore: ActionsStore;
  savedState: SavedState | undefined;
  saveState: (data: SavedState) => void;
  clearState: () => void;
}) {
  const { authStore, uiStore } = useContext(StoreContext),
    { t } = useTranslation(),
    history = useHistory(),
    [filtersDisplayed, setFiltersDisplayed] = useState(true);

  const didMount = useRef(false);
  const [criteria, setCriteria] = useState(() => {
    const defaultCriteria = { page: { size: 30 } };
    return {
      ...defaultCriteria,
      ...buildCriteria(actionsStore.filters),
    };
  });

  const actionsData = useGetActions(
    savedState?.actionsData ? savedState.actionsData : { page: 1 }
  );

  const {
    loading,
    actions,
    error,
    page,
    total,
    fetch,
    next,
    previous,
    exportCSV,
  } = actionsData;
  const [statusFilter, setStatusFilter] = useState('requested');

  useEffect(() => {
    uiStore.setTitle(t('Actions'));
  }, [uiStore, t]);

  useEffect(() => {
    if (didMount.current === false && savedState !== undefined) {
      return;
    }
    clearState();
    fetch(authStore.token, criteria);
    // eslint-disable-next-line
  }, [authStore.token, criteria]);

  useEffect(() => {
    if (didMount.current === false) {
      didMount.current = true;
      return;
    }

    setCriteria((previousCriteria) => ({
      ...buildCriteria(actionsStore.filters),
      page: previousCriteria.page,
    }));
  }, [actionsStore.filters]);

  const onToggleFilters = () => {
    setFiltersDisplayed((filtersDisplayed) => !filtersDisplayed);
  };

  const handleCurrentValue = (select: any) => {
    if (select && select.length === 1) {
      setStatusFilter(select[0].value);
    }
  };

  const handleChangePerPage = (perPage: number) => {
    setCriteria((prevCriteria) => ({
      ...prevCriteria,
      page: {
        size: perPage,
      },
    }));
  };

  const onSelectAction = (action: Action) => {
    saveState({
      actionsData,
      selectedItemID: action.ID,
    });

    history.push(`/action/${action.ID}`, { action, from: '/actions' });
  };

  const handleNext = () => {
    clearState();
    if (next) {
      next();
    }
  };

  const handlePrevious = () => {
    clearState();
    if (previous) {
      previous();
    }
  };


  const [brandID, setBrandID] = useState<string[] | undefined>(undefined);
  useEffect(() => {
    if (!actionsStore.filters) {
      setBrandID(undefined);
      return;
    }

    setBrandID(actionsStore.filters['brandId']?.valueNew);
  }, [actionsStore.filters]);



  return (
    <BrandProvider brandID={brandID}>
      <CSSTransition in={filtersDisplayed} timeout={200} classNames='filters'>
        <FiltersPanel
          pageStore={actionsStore}
          onToggleFilters={onToggleFilters}
        >
          <PanelFilterContentBlock
            label='Last detection date'
            initState={false}
          >
            {/*@ts-expect-error */}
            <DateFilter name='lastView' label='lastView' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock label={t('Generic')} initState={false}>
            <BrandsFilter label='brand' name='brandId' titleMode />
            <LocalizationFilter
              label='country'
              type='country'
              name='countries'
              placeholder={t('Select country')}
              titleMode
            />
            {/*@ts-expect-error */}
            <LocalizationFilter
              label='language'
              name='languages'
              placeholder={t('Select language')}
              titleMode
            />
            {/*@ts-expect-error */}
            <SelectFilter
              label='provider'
              name='engine'
              placeholder={t('Select engine')}
              options={engines}
              titleMode
            />
            {/*@ts-expect-error */}
            <SelectFilter
              label='type of campaign'
              name='presence'
              placeholder={t('Type of campaign')}
              options={[
                { label: t('Ads'), value: 'ads' },
                { label: 'Shopping', value: 'shopping' },
              ]}
              titleMode
            />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock
            label={t('Domains & tags')}
            initState={false}
          >
            <DomainFilter label='domain name' name='domain' titleMode />
            <TagsFilter
              label='tags (dealer)'
              name='dealer.categories.id'
              scope='tag'
              titleMode
            />
            <TagsFilter
              label='tags (keywords)'
              name='termRel.categories.id'
              scope='term'
              menuPlacement='top'
              titleMode
            />
            {/*@ts-expect-error */}
            <SelectFilter
              label='keyword type'
              name='termRel.keywordType'
              placeholder={t('Select type')}
              options={keywordtypes.map((o) => {
                return {
                  value: o.value,
                  label: t(o.label),
                };
              })}
              menuPlacement='top'
              titleMode
            />
            <TermsFilter
              label='keywords'
              name='term'
              filterKey='term'
              options={{ menuPlacement: 'top' }}
              nameForSearch='term'
              titleMode
            />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock label={t('Notifications')} initState={true}>
            {/*@ts-expect-error */}
            <SelectFilter
              label='status'
              name='status'
              currentValue={handleCurrentValue}
              options={statusActionsOptions.map((o) => ({
                label: t(o.label),
                value: o.value,
              }))}
              titleMode
            />
            {/*@ts-expect-error */}
            <SelectFilter
              label='reappearance'
              name='metas.originalStatus'
              placeholder={t('Select an option')}
              reactSelectOptions={{ isMulti: false }}
              options={[
                {
                  label: t('Only "reappearance"'),
                  value: 'reappearance',
                },
                {
                  label: t('Exclude "reappearance"'),
                  value: '-reappearance',
                },
              ]}
              titleMode
            />
            {/*@ts-expect-error */}
            <SelectFilter
              label='sortBy'
              name='sortBy'
              options={statusActionsSortOptions(statusFilter).map((o: any) => ({
                label: t(o.label),
                value: o.value,
                revertLabel: t(o.revertLabel),
                revertValue: o.revertValue,
              }))}
              titleMode
              optionsAutosort={false}
              reactSelectOptions={{ isMulti: false }}
            />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock
            label={t('First detection date')}
            initState={false}
          >
            {/*@ts-expect-error */}
            <DateFilter name='createdDate' label='createdDate' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock
            label={t('Requested date')}
            initState={false}
          >
            {/*@ts-expect-error */}
            <DateFilter name='requestedDate' label='requestedDate' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock
            label={t('Notification date')}
            initState={false}
          >
            {/*@ts-expect-error */}
            <DateFilter name='inprogressDate' label='inprogressDate' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock
            label={t('Acceptance date')}
            initState={false}
          >
            {/*@ts-expect-error */}
            <DateFilter name='acceptedDate' label='acceptedDate' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock label={t('Refused date')} initState={false}>
            {/*@ts-expect-error */}
            <DateFilter name='refusedDate' label='refusedDate' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock label={t('Updated date')} initState={false}>
            {/*@ts-expect-error */}
            <DateFilter name='updatedDate' label='updatedDate' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock
            label={t('Reappearance date')}
            initState={false}
          >
            {/*@ts-expect-error */}
            <DateFilter name='reappearanceDate' label='reappearanceDate' />
          </PanelFilterContentBlock>
          <PanelFilterContentBlock
            label={t('Disappeared date')}
            initState={false}
          >
            {/*@ts-expect-error */}
            <DateFilter name='disappearedDate' label='disappearedDate' />
          </PanelFilterContentBlock>
        </FiltersPanel>
      </CSSTransition>
      <main className={classes.main}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            {!filtersDisplayed && (
              <FiltersToggleButton
                pageStore={actionsStore}
                onToggleFilters={onToggleFilters}
              />
            )}
            <Breadcrumb excludeHomeSection />
          </h2>
          <div>
            <a href={exportCSV}>
              <FontAwesomeIcon icon={faDownload} />
            </a>
          </div>
        </div>
        <ShortNavFilter
          isLoading={loading}
          pageStore={actionsStore}
          counters={[{ txt: 'action', txtPlural: 'actions', count: total }]}
          countersNoParenthesis
        >
          {/*@ts-expect-error */}
          <DateFilter name='createdDate' label='createdDate' />
        </ShortNavFilter>

        {!!error && (
          <div className={classes.actions}>
            <div className={classes.noResultContainer}>
              <img alt='no result' src={ErrorResult} />
              <div className={classes.noResultTitle}>
                Une erreur est survenue
              </div>
              <div className={classes.noResultText}>
                Réessayez plus tard, si le problème persiste contactez-nous.
              </div>
            </div>
          </div>
        )}
        {loading === true && (
          <>
            <div className={classes.panelLoading}></div>
            <div className={classes.panelLoading}></div>
            <div className={classes.panelLoading}></div>
            <div className={classes.panelLoading}></div>
          </>
        )}
        {!error && loading === false && (
          <>
            {actions.length === 0 && (
              <div className={classes.actions}>
                <div className={classes.noResultContainer}>
                  <img alt='no result' src={NoResult} />
                  <div className={classes.noResultTitle}>
                    Aucun résultat trouvé
                  </div>
                  <div className={classes.noResultText}>
                    Aucune action ne correspond à la recherche que vous avez
                    faite.
                  </div>
                </div>
              </div>
            )}
            {actions.length > 0 && (
              <DynamicScrollbar className={classes.actions}>
                {actions.map((action) => (
                  <ActionPanel
                    key={action.ID}
                    hasFocus={action.ID === savedState?.selectedItemID}
                    action={action}
                    onSelect={onSelectAction}
                  />
                ))}
              </DynamicScrollbar>
            )}
          </>
        )}

        <CursorPagination
          total={total}
          perPage={criteria.page.size}
          currentPage={page}
          next={next ? handleNext : undefined}
          previous={previous ? handlePrevious : undefined}
          onChangePerPage={handleChangePerPage}
        />
      </main>
    </BrandProvider>
  );
}

export default observer(ActionsContainer);
