import { observer } from 'mobx-react';
import useTermsFilter from './useTermsFilter';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/NewAsyncCreateFilter';
import { useBrandContext } from '../../../components/brandContext';

interface Props extends FilterChild {
  readonly filterKey: string;
}

function TermsFilter(props: Props) {
  const { brandID } = useBrandContext();
  const useProps = useTermsFilter(props.filterKey, brandID, props?.options),
    passProps = { ...props, ...useProps };

  return <AsyncCreateFilter {...passProps} />;
}

export default observer(TermsFilter);
