import { useEffect, useContext } from 'react';
import AdsRow from '../../../components/AdsRow/AdsRow';
import useGetAdvertisements from '../../Hooks/data/getAdvertisements';
import DomainsStore from '../../../pages/Domains/domains.store';
import { ActionAdvertisementTable } from '../../../pages/Action/ActionAdvertisementList';
import { StoreContext } from '../../../stores';
import Loader from '../../Loader/Loader';

interface AdvertisementCriteria {
  domain: string;
  fromDate: string;
  endDate: string;
  brandId?: string | string[];
  engine?: string | string[];
  country?: string | string[];
  type?: string | string[];
  device?: string | string[];
  keywordType?: string | string[];
  keywordTag?: string[];
  title?: string;
  description?: string;
  region?: string[];
  keywordContent?: string[];
  position?: [number, number];
  limit: number;
}

export default function DomainsPortalCell({
  item,
  store,
}: {
  item: any;
  store: DomainsStore;
}) {
  const { authStore } = useContext(StoreContext);
  const { loading, advertisements, fetch } = useGetAdvertisements();

  useEffect(() => {
    const criteria: AdvertisementCriteria = {
      domain: item.domain,
      fromDate: store.filters.date.valueNew.start,
      endDate: store.filters.date.valueNew.end,
      limit: 50,
    };

    if (store.filters.brandID) {
      criteria.brandId = store.filters.brandID.valueNew;
    }
    if (store.filters.engine) {
      criteria.engine = store.filters.engine.valueNew;
    }
    if (store.filters.country) {
      criteria.country = store.filters.country.valueNew;
    }
    if (store.filters.adTitle) {
      criteria.title = store.filters.adTitle.valueNew;
    }
    if (store.filters.adDescription) {
      criteria.description = store.filters.adDescription.valueNew;
    }
    if (store.filters.region) {
      criteria.region = store.filters.region.valueNew;
    }
    if (store.filters.keywordContent) {
      criteria.keywordContent = store.filters.keywordContent.valueNew;
    }
    if (store.filters.position) {
      criteria.position = store.filters.position.valueNew;
    }
    if (store.filters.type) {
      criteria.type = store.filters.type.valueNew;
      if (typeof criteria.type === 'string') {
        criteria.type = criteria.type.split(',');
      }
    }
    if (store.filters.keywordType) {
      criteria.keywordType = store.filters.keywordType.valueNew;
      if (typeof criteria.keywordType === 'string') {
        criteria.keywordType = criteria.keywordType.split(',');
      }
    }
    if (store.filters.keywordTag) {
      criteria.keywordTag = store.filters.keywordTag.valueNew;
    }
    if (store.filters.device) {
      criteria.device = store.filters.device.valueNew;
      if (typeof criteria.device === 'string') {
        criteria.device = criteria.device.split(',');
      }
    }

    fetch(authStore.token, criteria);
  }, [fetch, item, authStore.token, store]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <AdsRow
      AdsInTable={
        <ActionAdvertisementTable
          advertisements={advertisements}
          shoppingType={false}
        />
      }
      onClose={() => store.setPortal()}
    />
  );
}
